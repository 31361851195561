.login-form {
  .login-button-spinner svg {
    color: white;
  }

  .ant-form {
    padding: 0px 54.62px 0px 55.84px !important;
  }

  .form-label {
    font-size: 16px;
    line-height: 25px;
  }

  .login-btn {
    width: 100%;
    height: 44px;
    font-size: 16px;
    font-weight: 600px !important;
    border-radius: 6px;
    color: white;
    background-color: rgb(214, 35, 20) !important;

    span {
      color: white !important;
    }
  }

  // .ant-btn-primary:hover{
  //     background-color:#ff0d50 !important;

  // }
}

.ant-input:focus,
:where(.css-dev-only-do-not-override-tkuui3).ant-input-focused {
  border-color: var(--background-color) !important;
  box-shadow: none !important;

}

.ant-input:hover {
  border-color: var(--background-color) !important;
  box-shadow: none !important;

}

.ant-input-affix-wrapper:focus {
  border-color: var(--background-color) !important;
  box-shadow: none !important;

}

.ant-input-affix-wrapper-focused {
  border-color: var(--background-color) !important;
  box-shadow: none !important;

}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: var(--background-color) !important;
  box-shadow: none !important;
}